// export default {
// 	host:'http://localhost',
// 	port:2465,
// 	socketurl:'ws://localhost:3469'
// }

export default {
	host:'https://server.mengyuanyiliao.com',
	port:2469,
	socketurl:'wss://server.mengyuanyiliao.com:3469'
}

// export default {
// 	host:'https://apis.agoda-chat.cc',
// 	port:2469,
// 	socketurl:'wss://apis.agoda-chat.cc:3469'
// }