<template>
  <Container
    title="系统参数设置"
    :returnBtn="false"
    :addBtn="false"
    :onConfirm="onConfirm"
  >
    <div style="width: 100%; display: flex; padding-bottom: 50px">
      <el-form
        :rules="rules"
        :model="form"
        label-width="auto"
        label-position="top"
        ref="formName"
        style="display: flex; width: 100%; flex-wrap: wrap"
      >
        <el-form-item
          label="系统名称"
          style="width: 24%; margin: 0.5%"
          prop="name"
        >
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item
          required
          label="客户端名称"
          style="width: 24%; margin: 0.5%"
          prop="appname"
        >
          <el-input v-model="form.appname" />
        </el-form-item>
        <el-form-item
          label="Token有效期(hours)"
          style="width: 24%; margin: 0.5%"
        >
          <el-input type="number" v-model="form.token_time" />
        </el-form-item>
        <el-form-item
          label="LongToken有效期(hours)"
          style="width: 24%; margin: 0.5%"
        >
          <el-input v-model="form.longtoken_time" />
        </el-form-item>
        <el-form-item label="服务接口地址" style="width: 24%; margin: 0.5%">
          <el-input v-model="form.serverurl" />
        </el-form-item>
        <el-form-item label="服务接口端口" style="width: 24%; margin: 0.5%">
          <el-input v-model="form.port" />
        </el-form-item>
        <el-form-item label="文件服务地址" style="width: 24%; margin: 0.5%">
          <el-input v-model="form.fileurl" />
        </el-form-item>
        <el-form-item
          required
          label="文件服务类型"
          style="width: 24%; margin: 0.5%"
        >
          <el-select v-model="form.filesavetype" placeholder="文件服务类型">
            <el-option label="Server" value="server" />
            <el-option label="Minio" value="minio" />
          </el-select>
        </el-form-item>
        <el-form-item label="微信appid" style="width: 24%; margin: 0.5%">
          <el-input v-model="form.wxappid" />
        </el-form-item>
        <el-form-item label="微信Secret" style="width: 24%; margin: 0.5%">
          <el-input v-model="form.wxsecret" />
        </el-form-item>
        <el-form-item label="微信商户号" style="width: 24%; margin: 0.5%">
          <el-input v-model="form.mchid" />
        </el-form-item>
        <el-form-item label="微信支付密钥" style="width: 24%; margin: 0.5%">
          <el-input v-model="form.paysecret" />
        </el-form-item>

        <el-form-item label="RTCURL" style="width: 24%; margin: 0.5%">
          <el-input v-model="form.rtcurl" />
        </el-form-item>
        <el-form-item label="RTCIP" style="width: 24%; margin: 0.5%">
          <el-input v-model="form.rtcip" />
        </el-form-item>

        <el-form-item label="注册短信认证" style="width: 24%; margin: 0.5%">
          <el-select v-model="form.smsrun">
             <el-option label="关闭" value="关闭" />
            <el-option label="开启" value="开启" />
          </el-select>
        </el-form-item>

        <el-form-item label="短信KEY" style="width: 24%; margin: 0.5%">
          <el-input v-model="form.smskey" />
        </el-form-item>
        <el-form-item label="短信密钥" style="width: 24%; margin: 0.5%">
          <el-input v-model="form.smssecret" />
        </el-form-item>

        <el-form-item label="会员等级逻辑" style="width: 24%; margin: 0.5%">
          <el-select v-model="form.chatgroupmax">
            <el-option label="关闭" value="关闭" />
            <el-option label="开启" value="开启" />
          </el-select>
        </el-form-item>

        <el-form-item label="默认群限制" style="width: 24%; margin: 0.5%">
          <el-input v-model="form.defgmax" />
        </el-form-item>
        <el-form-item label="默认群人数限制" style="width: 24%; margin: 0.5%">
          <el-input v-model="form.defumax" />
        </el-form-item>

        <el-form-item label="直播间默认权限开启" style="width: 24%; margin: 0.5%">
          <el-select v-model="form.liveroom">
            <el-option label="关闭" value="关闭" />
            <el-option label="开启" value="开启" />
          </el-select>
        </el-form-item>

        <el-form-item label="会议室默认权限开启" style="width: 24%; margin: 0.5%">
          <el-select v-model="form.metting">
            <el-option label="关闭" value="关闭" />
            <el-option label="开启" value="开启" />
          </el-select>
        </el-form-item>

        <el-form-item label="Logo" style="width: 100%; margin: 0.5%">
          <el-upload
            class="avatar-uploader"
            :action="uploadurl + '/upload/upload/image'"
            :show-file-list="false"
            :on-success="
              (e) => {
                this.form.logo = e.data;
              }
            "
          >
            <img
              v-if="form.logo"
              :src="$store.state.common.systemInfo.fileurl + form.logo"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">
              <el-icon><UploadFilled /></el-icon>
            </i>
          </el-upload>
        </el-form-item>

        <el-form-item label="充值说明" style="width: 100%; margin: 0.5%">
          <FormEditItem :value="form.content" :form="form" name="content" />
        </el-form-item>
      </el-form>
    </div>
  </Container>
</template>

<script>
import moment from "moment";
import config from "../../../common/config";
import { pub } from "../../../common/event";
export default {
  data: () => {
    return {
      form: {},
      rules: {},
      uploadurl:''
    };
  },
  mounted: function () {
    this.uploadurl = config.host + ":" + config.port
    this.init();
  },
  methods: {
    onConfirm: async function () {
      console.log(this.form);
      this.$refs["formName"].validate(async (valid) => {
        if (valid) {
          const ret = await this.$api.system.putSystemInfo(this.form);
          if (ret) {
            pub("success_message", "编辑成功");
            pub("sysinfochange", this.form);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    init: async function () {
      const ret = await this.$api.system.getSystemInfo();
     
      const formdb = {};
      for (let k in ret.data) {
        if (!k.includes('custom')) {
          formdb[k] = ret.data[k];
        }
      }
      console.log("formdb", formdb);
      this.form = formdb;
    },
    init1: async function () {
      const ret = await this.$api.system.getSystemInfo();
      console.log(ret);
      const fieldret = await this.$r.post(
        `common/common/getdata/dbfieldsinfo`,
        { where: { dbid: 1 } }
      );
      console.log("fieldret", fieldret);
      const farr = fieldret.data.map((e) => e.name);
      const formdb = {};
      for (let k in ret.data) {
        if (farr.includes(k)) {
          formdb[k] = ret.data[k];
        }
      }
      console.log("formdb", formdb);
      this.form = formdb;
    },
    beforeAvatarUpload: function () {},
    handleAvatarSuccess: function (e) {
      this.form.logo = e.data;
    },
  },
};
</script>

<style scoped>
.errorcss {
  border: 1px solid #ff352e88;
  border-radius: 3px;
}
</style>